@import '../fonts/Metropolis.css';

/* Variables */
:root {
  --color-primary: #8e50a7;
  --color-primary-hover: #744188;
  --color-secondary: #8e50a7;
  --color-lt-gray: #f6f6f6;
  --color-mid-gray: #dfdfdf;
  --color-gray: #6d6d6d;
  --color-dk-gray: #101828;
  --color-blue: #4081de;
  --color-eggplant-100: #f4eff5;
  --color-eggplant-200: #ddd0e1;
  --color-eggplant-600: #9873A6;
  --color-eggplant-700: #63466D;
  --color-eggplant-900: #46324e;
  --color-magenta-900: #8e50a7;
  --color-magenta-700: #B386C5;
  --color-peach-600: #fbf1e9;
  --color-royal-900: #286bcb;
  --color-royal-100: #eef5ff;
  --color-yellow: #f0dfbe;  
}

@mixin gradient-bg {
  background-image: linear-gradient(#824c93, #d0a5c3);
}

body {
  background-color: var(--color-lt-gray);
  font-family: 'Metropolis Regular';
}

h1,
h2,
h3 {
  color: var(--color-dk-gray);
}
.larger{
  font-size: xxx-large;
}
.text-primary {
  color: var(--color-primary) !important;
}
.extra_light_grey_bg{
  background: #fcfcfc;
}
.extra_light2_grey_bg{
  background: #f9f9f9;
}

.light_grey_bg{
  background: var(--color-lt-gray);
}
.mid_grey_bg{
  background: var(--color-mid-gray);
}
.eggplant-100-bg {
  background-color: var(--color-eggplant-100);
}
.eggplant-200-bg {
  background-color: var(--color-eggplant-200);
}
.peach-600-bg {
  background-color: var(--color-peach-600);
}
.eggplant-900 {
  background-color: var(--color-eggplant-900);
}
.eggplant-600-text {
  color: var(--color-eggplant-600);
}
.text-light {
  opacity: 0.6;
  color: inherit !important;
}

.text-mid {
  font-family: 'Metropolis Semi Bold';
}

.left {
  float: left;
}
.right {
  float: right;
}
.underline {
  text-decoration: underline;
}
.flexcenter {
  align-items: center;
}

.subheading {
  width: auto;
  padding-right: 40px;
}

.tag {
  padding-right: 8px;
}

strong {
  font-family: 'Metropolis Bold';
}

a {
  color: var(--color-primary);
  text-decoration: none;
  
  &:hover {
    color: var(--color-primary-hover);
  }

  &.disabled {
    pointer-events: none;
  }
}
.react-datepicker__tab-loop{
  position: absolute;
}
.tick_li li{
  list-style: none; 
    background-image: url('../images/icons/icon-tick.svg'); 
    background-size: 10px; 
    background-position: 0 6px;
    background-repeat: no-repeat;
    padding-left: 30px; 
    margin-bottom: 6px;

}
.tick_li.white li{
      background-image: url('../images/icons/icon-tick-white.svg'); 
    }

.icon-error{
  width: 16px;
}

/* FORM controls */
.input-1col {
  display: inline-block;
  width: 20%;
}
.input-2col {
  display: inline-block;
  width: 52%;
}
.input-2-5col {
  display: inline-block;
  width: 60%;
}
.input-3col {
  display: inline-block;
  width: 74%;
}
.label-2col {
  display: inline-block;
  width: 20%;
}

.label-1col {
  display: inline-block;
  width: 20%;
}
.clear{
  clear:both;
  display: block !important;
}
.hr {
  border-top: 1px solid var(--color-gray);
  clear: both;
  width: 90%;
}
.mid-gray {
  color: var(--color-mid-gray);
}
.gray {
  color: var(--color-gray);
}
.flex {
  display: grid;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.pulse_loader {
  display: inline-block;
  font-size: 30px;
  overflow: hidden;
  width: 1.1em;
  height: 1em;
}

.pulse_loader::before,
.pulse_loader::after,
.pulse_loader {
  animation: pulse 1s infinite;
}

.pulse_loader::before {
  content: ".";
  margin-right: 0.2em;
}

.pulse_loader::after {
  content: "...";
  margin-left: 0.2em;
}

/* Table Styles */
table {
  border-collapse: collapse;
  width: 100%;
}
table thead {
  background-color: var(--color-lt-gray);
}

table tbody tr, table thead tr {
  border: 1px solid var(--color-mid-gray);
}

table td, table th {
  padding: 15px !important;
  text-align: left;
}


/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -6px; /* Centers thumb on the track */
  background-color: var(--color-primary);
  height: 1.5rem;
  width: 1.5rem;
}
input[type=radio] {
  margin-right: 8px;
}

/* Layout */
header {
  background: #fff;
  min-height: 80px;
  width: 100%;

  .logo-bk {
    margin: 24px 30px;
  }

  h1 {
    margin: 0;
    font-size: 28px;
    font-family: 'Metropolis Semi Bold';
    line-height: 1;
  }

  .breadcrumbs {
    margin: 0;
    padding: 12px 0 0;
    list-style: none;

    li {
      display: inline-block;
      font-size: 11px;
      font-family: 'Metropolis Semi Bold';
      text-transform: uppercase;
      letter-spacing: 0.14em;
      color: var(--color-gray);
    }

    a {
      color: var(--color-primary);
    }
  }
}

.tabbed-wrapper {
  background-color: var(--color-lt-gray);
  width: 80%;
  margin-left: 50px;
  position: absolute;
  ul {
    margin: 0;
    padding: 12px 0 0;
    list-style: none;

    li {
      display: inline-block;
      font-size: 11px;
      font-family: 'Metropolis Semi Bold';
      text-transform: uppercase;
      letter-spacing: 0.14em;
      color: var(--color-gray);
      padding: 2px 26px 10px 0;
      border-bottom: 3px solid var(--bs-gray-400);

      a {
        color: var(--color-gray);
      }
    }
    .active {
      border-bottom: 3px solid var(--color-primary);
    }
  }
}
.disabled_overlay{
  width: 100%;
  background: rgba(180, 180, 180, 0.1098039216);
  height: 100%;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
}
nav {
  @include gradient-bg;
  width: 72px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  ul {
    margin: 20px 0 0;
    padding: 0;

    li {
      display: block;
      margin-bottom: 20px;

      a {
        display: block;
        position: relative;
        text-align: center;
        height: 50px;
        padding: 13px 0;
        opacity: 0.6;
        transition: ease all 0.2s;

        &.on {
          opacity: 1;

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 6px;
            height: 100%;
            background: #fff;
          }
        }
      }

      &.ai_assistant{
        margin: auto;
        margin-bottom: 7px;
        max-height: 50px;
        a{
          opacity: 0.3;
        }
      }
      &:hover, &.active_assistant {
        a {
          opacity: 1;
        }
      }
    }
  }

  .nav-btn {
    display: block;
    font-size: 12px;
    font-weight: bold;
    border: none;
    background: none;
    color: #fff;
    margin: 0 auto;
    padding: 5px 0;

    &.on {
      opacity: 1;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 6px;
        height: 100%;
        background: #fff;
      }
    }
  }

  footer {
    position: fixed;
    bottom: 20px;
    width: 72px;

    .btn-account {
      display: block;
      position: relative;
      text-align: center;
      height: 50px;
      opacity: 0.6;
      transition: ease all 0.2s;

      img{
        margin-top: 10px;
      }

      &:hover, &.on {
        opacity: 1;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 6px;
          height: 100%;
          background: #fff;
        }
      }
    }

    .btn-logout {
      display: block;
      font-size: 12px;
      font-weight: bold;
      border: none;
      background: none;
      color: #fff;
      margin: 0 auto;
      padding: 5px 0;
      width: 48px;
      border-bottom: 1px solid #fff;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 145px;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 16px;  
}


.dashboard {
  margin-left: 72px;

  .wrapper {
    padding: 48px 48px 24px;
    background: #fff;

    .wf_ai_assistant{
        position: absolute;
        right: 64px;
        top: 80px;

      img{
       width: 85px;
       height: 85px;
       padding: 0;
      }
    }
  }

  .card.card-solutions {
    border-radius: 0 0 5px 5px;
    border-top: none;
    margin-bottom: 24px;
    
    .card-header {
      background: #f4eff5;
      height: 66px;
      line-height: 66px;
      padding: 0 30px;
      border: none;
      border-left: solid 0px var(--color-primary);
      font-size: 24px;
      font-family: 'Metropolis Semi Bold';
      color: var(--color-primary);
      border-radius: 0;
      transition: ease all 0.25s;

      img {
        margin-right: 15px;
        margin-top: 5px;
        height: 23px;
      }

      .right {
        text-align: right
      }
    }

    .card-body {
      padding: 30px;
      min-height: 112px;
    }

    
    .ai_assistant{
        width: 85px;
        height: 85px !important;
        padding: 0;
      
    }

    .card-buttons {
      padding: 0 30px;
    }

    .card-footer {
      background: none;
      border: none;
      font-size: 10px;
      letter-spacing: 0.14em;
      text-transform: uppercase;
      padding: 30px;
      color: var(--color-primary);
    }

    &:hover .card-header {
      border-left: solid 4px var(--color-primary);
    }
  }

  .card.card-workflows {
    border-radius: 0 5px 5px 0;
    margin-bottom: 24px;
    background: var(--color-lt-gray);
    border-left: 3px solid var(--color-gray);
    color: inherit;

    .card-header {
      background: none;
      height: 66px;
      line-height: 21px;
      padding: 24px 30px 0px 30px;
      border: none;
      font-size: 18px;
      font-family: 'Metropolis Semi Bold';

      .row {
        padding-left: 0;
        padding-right: 0;
      }

      img {
        margin-right: 15px;
        height: 23px;
      }
    }

    .card-body {
      padding: 30px;
      min-height: 112px;
    }
    
    .card-footer {
      background: none;
      border: none;
      font-size: 10px;
      letter-spacing: 0.14em;
      padding: 30px;
      text-align: right;
    }

    &.coming-soon {
      opacity: .7;
      background: #e8e8e8;
    }
    
    &:hover {
      border-color: var(--color-primary-hover);
  
      .card-header {
        color: var(--color-primary-hover);
      }
  
      .btn-primary {
        background-color: var(--color-primary-hover) !important;
      }
    }
  }

  .card.card-workflows.recipe_active{
    border-left: 3px solid var( --color-royal-900);
    background: var(--color-royal-100);
  }
  

  .card.card-combinations {
    border-radius: 0 5px 5px 0;
    margin-bottom: 24px;
    background: var(--color-lt-gray);
    border-left: 3px solid var(--color-gray);
    min-height: 308px;

    .card-header {
      background: none;
      padding: 15px 10px 0;
      border: none;
      font-size: 18px;

      .row > * {
        padding-left: 0;
        padding-right: 0;
      }

      strong {
        font-family: 'Metropolis Semi Bold';
      }

      img {
        margin-right: 15px;
        margin-top: 5px;
        height: 22px;
      }

      .ai_assistant{
        width:70px;
        height: 70px;
      }

      .right {
        text-align: right;
      }
    }

    .card-body {
      padding: 0 10px;

      h4 {
        font-size: 10px;
        letter-spacing: 0.14em;
        text-transform: uppercase;
      }
    }



    .card-footer {
      background: none;
      border: none;
      font-size: 10px;
      letter-spacing: 0.14em;
      padding: 0 0 30px;
      text-align: right;

      .btn-primary {
        transition: ease all 0.25s;
        margin-right: 0px;
      }
      
      .btn-vote {
        transition: ease all 0.4s;
        transform: scale(1);
      }
    }
 
    &.connected {
      border-left: 3px solid var(--color-blue);
    }

    &.coming-soon {
      opacity: .7;
      background: #e8e8e8;
    }

    &:hover {
      border-color: var(--color-primary-hover);
  
      .card-header {
        color: var(--color-primary-hover);
      }
  
      .btn-primary {
        background-color: var(--color-primary-hover) !important;
        margin-right: -5px;
      }
      
      .btn-vote {
        background-color: var(--color-primary-hover) !important;
        transform: scale(1.2);
      }
    }


  }
  // .card.card-combinations.coming-soon{
  //   background: var(--color-mid-gray);
  // }

  .workato-embed {
    height: 100vh;
  }

  .nav-tabs {
    margin: 10px 0;
    border: none;

    .nav-link {
      padding: 0 26px 0 0;
      line-height: 40px;
      border: none;
      color: var(--color-gray);
      border-bottom: 3px solid var(--color-gray);
      font-family: 'Metropolis Bold';
      
      &.active {
        background: none;
        color: var(--color-primary-hover);
        border-bottom: 3px solid var(--color-primary-hover);
      }
    }

  }

  .tabs-connection {
    display: flex;
    flex-wrap: wrap;

    .nav-tabs {
      display: block;
      flex: 0 0 auto;
      width: 33.33333333%;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      margin-top: var(--bs-gutter-y);

      .nav-item {
        padding: 1px 1rem 1rem;
        background: white;

        &:first-child {
          padding-top: 1rem;
        }

        .nav-link {
          color: var(-color-dk-gray) !important;
          border: none;
          padding: 10px 20px;
          width: 100%;
          text-align: left;
          border-radius: 5px;
          
          &.active {
            color: var(--color-primary-hover) !important;
            border: 1px solid var(--color-primary);
            padding: 10px 20px;
          }
        }

      }
    }

    .tab-content {
      display: block;
      flex: 0 0 auto;
      width: 66.66666667%;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      margin-top: var(--bs-gutter-y);
    }
  }

  .workflowData {
    h5 {
      font-size: 11px;
      font-family: 'Metropolis Semi Bold';
      text-transform: uppercase;
      letter-spacing: 0.14em;
      color: var(--color-gray);
      margin-bottom: 18px;
    }

    .card {
      padding: 10px;
      margin-bottom: 50px;
      position: relative;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -30px;
        left: 70px;
        width: 1px;
        height: 20px;
        background: var(--color-gray);
      }

      .icon-solutions {
        margin-right: 20px;
      }
    }
    .tall_wf_tile {
      padding: 25px 10px;
    }
    .end {
      display: block;
      width: 30px;
      height: 30px;
      text-align: center;
      font-size: 11px;
      font-family: 'Metropolis Semi Bold';
      text-transform: uppercase;
      letter-spacing: 0.14em;
      color: var(--color-primary);
      margin-left: 57px;

      &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background: var(--color-primary);
      }
    }
  }
}


.monitoring {
  .nav-tabs {
    background-color: var(--color-eggplant-900);
    margin: 10px 0;
    border: none;

    .nav-item {
      color: white;
      width: 100%;

      &:first-child {
        padding-top: 1rem;
      }

      .nav-link {
        color: #fff !important;
        border: none;
        padding: 10px 20px;
        width: 100%;
        text-align: left;
        border-radius: 5px;
        
        &.active {
          background: var(--color-magenta-900);
          border: 1px solid var(--color-primary);
          padding: 10px 20px;
        }
      }

    }
  }
}

.wf-btn,
.wf-item-status-connected,
.wf-item-status-notconnected,
.wf-item-status-notconnected:active {
  background: var(--color-eggplant-200);
  border-radius: 4px;
  position: absolute;
  right: 10px;
  top: 17px;
  padding: 1px 10px;
  border: none;
  
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn{
  background: var(--color-magenta-700);
}

.wf-item-status-notconnected {
  background: #efefef;
}
.selected_wf_item {
  border: 1px solid var(--color-royal-900);
  background-color: var(--color-royal-100);
}
.home-accordian-item{
  background-color: var(--color-eggplant-700);
  color: white;
  .accordion-button{
    background-color: var(--color-eggplant-700);
    color: white;
    font-size: 20px;
    box-shadow: none;
  }
}
.home-accordian-item .accordion-button::after{
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.home_ccordian_white{
  .accordion-button{
    background-color: white;
    font-size: 20px;
    box-shadow: none;
    color: var(--color-eggplant-700);
  }
}

.box-white {
  background: #fff;
  padding: 2rem;
}
.white-text{
  color: white;
}

.icon-solutions {
  background: var(--color-primary);
  display: inline-block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  margin-right: 8px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  overflow: hidden;
}

.combination-recipe {
  margin-top: 47px;
}

.mobile-desktop-warning {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-mid-gray);
  z-index: 9999;
  padding: 2rem;

  h2, p {
    color: var(--color-gray);
  }
}

.fullscreen {
  @include gradient-bg;

  color: #fff;

  header {
    position: fixed;
    top: 0;
    left: 0;
  }

  h1 {
    color: #fff;
    font-size: 72px;
  }
}

.h-100vh {
  height: 100vh;
}
.mh_100{
  max-height: 100vh;
}

/* Forms */
.btn-primary {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary) !important;
  border-radius: 0;

  &:hover {
    background-color: var(--color-primary-hover) !important;
  }
}
.btn-primary-xero {
  background-color: white;
  border: none;
  border-radius: 0;
  margin-top: 7px;

  &:hover {
    background-color: white;
  }
}
.xero-btn{
  height: 35px;
  position: absolute;
  margin-left: -75px;
  margin-top: -5px;
}


.btn-vote {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary) !important;
  border-radius: 17px;

  &:hover {
    background-color: var(--color-primary-hover) !important;
  }
}

.btn-secondary {
  background: none;
  border: 1px solid var(--color-primary) !important;
  color: var(--color-primary);
  border-radius: 0;

  &:hover {
    background-color: var(--color-primary-hover) !important;
  }
}
.btn-trans, .btn-trans:hover{
  color:white;
  background-color: transparent !important;
  border: none !important;
}
.relative{
  position: relative;
}
.search,
.subsearch .search {
  display: block;
  float: right;
  border: none;
  width: 410px;
  height: 40px;
  margin-top: 18px;
  background: var(--color-lt-gray);
  position: relative;

  input {
    height: 100%;
    width: 370px;
    float: right;
    border: none;
    background: none;
    outline: none;
  }

  img {
    position: absolute;
    top: 13px;
    left: 10px;
  }
}

.subsearch {
  margin-bottom: 25px;

  .search {
    margin-top: 0px;
  }
}

label, th {
  font-size: 9px;
  color: var(--color-dk-gray);
  text-transform: uppercase;
  letter-spacing: 0.14em;
}

/* Login */
.login,
.register
{
  h1 {
    font-size: 32px;
  }

  p {
    color: var(--color-gray);
  }

  .form-control,
  .form-select {
    border-radius: 0;
  }

  .btn-primary {
    width: 100%;

    &:disabled {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
      opacity: 0.5;
    }
  }
}
.santa_hat {
  z-index: 2;
  position: fixed;
  transform: scaleX(-1);
  width: auto;
  top: 171px;
  margin-left: 80px;
}
.santa-text {
  bottom: 77%;
  right: 29%;
  position: fixed;
  color: white;
}
.fade-in {
  animation: fadeIn 4s;
}
.fade-out {
  animation: fadeOut 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.hidden {
  display: none;
}
.login-left,
.register-left
{
  position: relative;

  .register {
    padding-top: 125px;
  }
  header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }

  
  footer {
    font-style: normal;
    font-family: 'Metropolis Bold';
    font-size: 10px;
    letter-spacing: 1.3px;
    line-height: 20px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: var(--color-gray);
  }
}

.basic-form {
  h3 {
    font-size: 18px;
    font-family: "Metropolis Semi Bold";
    margin-bottom: 20px;
  }
}

/* Badges */
.beta {
  background: var(--color-yellow);
  display: inline-block;
  line-height: 25px;
  padding: 0 15px;
  font-style: normal;
  font-family: 'Metropolis Bold';
  font-size: 10px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  width: inherit !important;
  color: #212529;
}
.card-beta{
  width:auto !important;
}

.badge-status {
  display: inline-block;
  line-height: 25px;
  padding: 0 9px;
  font-style: normal;
  font-family: 'Metropolis Bold';
  font-size: 10px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  
  &.inactive {
    background: #DFDFDF;
    color: #A8A8A8;
  }
  
  &.connected {
    background: #EEF5FF;
    color: #4081DE;
  }

  &.comingsoon {
    background: var(--color-yellow);
    color: #212529;
  }
}


.login-right,
.register-right
 {
  @include gradient-bg;

  .promo_pic_abs {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 49%;
  }
}

.assistant_thumb{
  width: 85px;
  height:85px
}
.assistant_summary_card{
  background: #ffffff;
  margin-right: 8px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    overflow: hidden;
    display: inline-block;
    padding: 12px;
    color: purple;
    font-size: 20px;

    &.large{
      padding: 20px;
      font-size: 25px;
      line-height: 34px;
    }
}
.inactive_img{
  opacity: 0.3;
  &:hover{
    opacity: 1;
  }
}

/* Model tweaks */
.modal-content  {
max-height: 92vh;
}
.modal-content .row {
  margin-right: 0;
  margin-left: 0;
  overflow: hidden;
}
.modal-content .row .col{
height: 75vh;

}
.custom-modal {
  max-width: 80%;
}



/* Hide default checkbox */
.custom-tick{
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}
.custom-tick input[type="checkbox"] {
  display: none;
}


/* Dark purple checkmark */
.purple-checkmark {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: var(--color-primary-hover); /* change to dark purple */
  border-radius: 3px;
  margin-right: 5px;
}

/* Light purple checkbox background */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 3px;
  border: 1px solid;
  border: 1px solid #6c3483;
  background-color: var(--color-eggplant-100); /* change to light purple */
}

/* Show custom checkmark when checkbox is checked */
.custom-tick
input[type="checkbox"]:checked + .checkmark {
  background-image: url("../images/icons/check-icon.png"); 
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 59%;
}

/* Show dark purple checkmark when checkbox is checked */
input[type="checkbox"]:checked + .checkmark + .purple-checkmark {
  background-color: #6c3483; /* change to dark purple */
}

.platform_version{
  color: white;
  text-align: center;
  width: 100%;
  display: block;
  padding: 10px 0 0 0;
}

/* custom tool tip */
.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  z-index: 1050;
}

.tooltip.float-r {
  float: right;
  margin-top: 20px;
}

.tooltip_icon {
  width: 20px;

}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 213px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 12px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext.bottom {
  width: 320px;
  height: 100px;
  top: 125%;
  margin-left: -160px;
}

.tooltip .tooltiptext.right {
  width: 480px;
  height: 80px;
  top: -10px;
  left: -490px;
  margin: 0;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip .tooltiptext.bottom::after {
  top: -10px;
  border-color: transparent transparent #555 transparent;
}

.tooltip .tooltiptext.right::after {
  top: 40%;
  border-color: transparent transparent transparent #555;
  margin: 0 0 0 240px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
/* benefits front pages */
.benefits_trial{
    margin: 52px 65px;
    color: white;
    padding: 15px;

  h1{
    text-align: center;
    color:white;
    margin-bottom: 60px;
  }
  h3{
    color:white;
    font-size: 29px;
  }
  p{
    font-size: 20px;
  }
  ul {
    position: relative;
    list-style-type: none;
    li {
      text-align: center;
      position: absolute;
      margin-top: 200px;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

        img{
          width: 125px;
          clear: both;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }

        &.active {
          opacity: 1;
          visibility: visible;
        }
    }
 
  }
}
  


@keyframes show-benefits {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/**/



/* On screens that are 1600 or more,  */
@media screen and (min-width: 1600px) {
  .login-right,
  .register-right {
  .promo_pic_abs {
    position: fixed;
    width: 29%;
    margin-right: 0px;
  }
}
}

/* On screens that are 1600 or less,  */
@media screen and (max-width: 1600px) {
  .login-right,
  .register-right {
  .promo_pic_abs {
    position: fixed;
    width: 31%;
    margin-right: 0px;
  }
}
}

/* On screens that are 480 or less,  */
@media screen and (max-width: 480px) {
  .login-right,
  .register-right {
    .promo_pic_abs {
      position: relative;
      bottom: unset;
      right: unset;
      width: 131%;
      margin-left: -108px;
      margin-top: -74px;
    }
  }
  .login-left,
  .register-left {
    footer {
      text-align: center;
      position: relative;
      display: block;
      left: inherit;
      bottom: inherit;
      padding: 25px;
    }
  }
}

