@font-face {
    font-family: 'Metropolis Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Regular'), url('Metropolis-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Metropolis Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Regular Italic'), url('Metropolis-RegularItalic.woff') format('woff');
}
@font-face {
    font-family: 'Metropolis Light';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Light'), url('Metropolis-Light.woff') format('woff');
}
@font-face {
    font-family: 'Metropolis Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Light Italic'), url('Metropolis-LightItalic.woff') format('woff');
}
@font-face {
    font-family: 'Metropolis Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Bold'), url('Metropolis-Bold.woff') format('woff');
}
@font-face {
    font-family: 'Metropolis Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Bold Italic'), url('Metropolis-BoldItalic.woff') format('woff');
}
@font-face {
    font-family: 'Metropolis Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Semi Bold'), url('Metropolis-SemiBold.woff') format('woff');
}
@font-face {
    font-family: 'Metropolis Semi Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Semi Bold Italic'), url('Metropolis-SemiBoldItalic.woff') format('woff');
}