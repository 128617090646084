/* Variables */
:root {
  --color-primary: #8e50a7;
  --color-primary-hover: #744188;
  --color-secondary: #8e50a7;
  --color-lt-gray: #f6f6f6;
  --color-mid-gray: #dfdfdf;
  --color-dk-gray: #101828;
  --color-gray: #6d6d6d;
  --color-blue: #4081de;
  --color-eggplant-100: #f4eff5;
  --color-eggplant-200: #ddd0e1;
  --color-peach-600: #fbf1e9;
  --color-royal-900: #286bcb;
  --color-royal-100: #eef5ff;
  --color-yellow: #F0DFBD;
}

.fullscreen {
  background: none;
}

.bgGray {
    background: var(--color-yellow);
    text-align: center;
    padding: 4rem;

    h1 {
        color: var(--color-primary);
    }
}
.bgPurple1 {
    background: var(--color-primary);
    text-align: center;
    padding: 2rem 4rem;
    
    h3 {
        color: #fff;
    }
}
.bgPurple2 {
    background: var(--color-primary-hover);
    text-align: center;
    padding: 2rem 4rem;

    h3 {
        color: #fff;
    }
}
